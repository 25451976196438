import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import TestimonialCard from "../components/TestimonialCard"

const TestamonialsPage = () => (
  <Layout>
    <SEO title="Testamonials" />
    <h1>Testamonials</h1>
    <p>Content coming soon!</p>
    {/*<div style="display: flex">
      <TestimonialCard
        title="Ada"
        image="/ada-main.jpg"
        summary="She's a happy and healthy girl, very obedient! And loves her walks and she always has to have a eye on you!"
        byline="Aaron, owner of one of our stunning dogs, a dark beauty Ada 😍"
      >
      "She's a happy and healthy girl, very obedient! And loves her walks and she always has to have a eye on you! My room mate took her to the local oval and had a guy approach him asking if she was a police dog due to how obedient she is."
      </TestimonialCard>
    </div>*/}
  </Layout>
)

export default TestamonialsPage

